<template>
  <div>
    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <software-table-simulation />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col 
        lg="12"
        sm="12"
      >
        <software-table-simulation-2 />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import softwareTableSimulation from './softwareTableSimulation.vue'
import softwareTableSimulation2 from './softwareTableSimulation2.vue'

export default {
  components: {
    BRow,
    BCol,
    softwareTableSimulation,
    softwareTableSimulation2
  },
  data() {
    return {
      data: {},
    }
  },
  created() {
    // data
    this.$http.get('/analytics/data')
      .then(response => { this.data = response.data })
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>